@import '~styles/common-mixins.scss';
@import '~atoms/Button/size-mixins.scss';

.subscriptionBlock {
  display: flex;
  flex-direction: column;

  @include upperTablet {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include upperTablet {
      inline-size: 50%;
      margin-inline-end: 16px;

      @include onlyLargeDesktop {
        inline-size: 60%;
        margin-inline-end: 24px;
      }
    }
  }

  &__header {
    margin-block-end: 12px;
    font-size: 28px;
    line-height: 36px;
    
    @include upperTablet {
      font-size: 32px;
      line-height: 40px;
      margin-block-end: 16px;
    }
  
    @include onlyLargeDesktop {
      margin-block-end: 20px;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    
    @include upperTablet {
      font-size: 18px;
      line-height: 26px;
    }
  }

  &__image {
    &_mobile {
      inline-size: 100%;
      margin-block-start: 24px;

      @include upperTablet {
        display: none;
      }
    }

    &_tablet {
      display: none;
      float: right;
      inline-size: 50%;

      @include upperTablet {
        display: block;
      }

      @include onlyLargeDesktop {
        inline-size: 40%;
      }
    }
  }

  &__targetBtn {
    inline-size: 100%;
    margin-block-start: 24px;

    @include buttonPrimaryBySize('m');

    @include upperWideMobile {
      @include buttonPrimaryBySize('l')
    }

    @include upperTablet {
      inline-size: auto;
      margin-block-start: 32px;
    }

    @include onlyLargeDesktop {
      margin-block-start: 40px;
    }
  }

  &__sponsorLink {
    margin-block-start: 20px;
    margin-inline-start: auto;

    @include upperTablet {
      position: absolute;
      inset-block-end: 0;
      inset-inline-end: 0;
    }
  }

  &__spoiler {
    white-space: nowrap;
  }
}