@import '~styles/common-mixins.scss';
@import '~atoms/Button/size-mixins.scss';

.productCard {
  z-index: 0;
  position: relative;
  box-sizing: border-box;
  inline-size: 100%;
  block-size: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
  text-align: start;
  background-color: var(--card-background-color);

  border-radius: 16px;
  --product-card-padding: 8px;
  padding: var(--product-card-padding);

  @include upperWideMobile {
    border-radius: 24px;
    --product-card-padding: 16px;
  }

  @include onlyLargeDesktop {
    --product-card-padding: 20px;
  }

  &__favoriteBtn {
    z-index: 1;
    position: absolute;
    inset-inline-end: var(--product-card-padding, 0);
    inset-block-start: var(--product-card-padding, 0);
    border-radius: 360px;
    color: inherit;

    svg {
      display: block;
    }

    &:not(:disabled):hover,
    &:not(:disabled):active {
      svg {
        fill: currentColor;
      }
    }

    &:hover {
      color: var(--button-icon-color-hover);
    }

    &:active {
      color: var(--button-icon-color-active);
    }
  }

  &__image {
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-grow: 1;
    align-self: center;
    inline-size: 100%;
    max-block-size: 100%;
    min-block-size: 124px;
    margin-block-end: 8px;
  }

  &__discount {
    position: absolute;
    inset-inline-start: 0;
    inset-block-end: 0;
    background: var(--discount-background);
    font-size: 14px;
    line-height: 15px;
    padding-inline: 6px;
    border-radius: 360px;

    & > * {
      @include useGradientTextColor(var(--discount-text-color));
    }
  }

  &__currentPrice {
    color: var(--title-text-color);
    font-size: 20px;
    line-height: 28px;

    @include upperDesktop {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__oldPrice {
    color: var(--canceled-text-color);
    text-decoration: line-through;
    font-size: 14px;
    line-height: 17px;

    @include upperDesktop {
      font-size: 16px;
      line-height: 20px;
    }
  }
  
  &__description {
    font-size: 14px;
    --product-description-line-height: 20px;
    line-height: var(--product-description-line-height);
    height: calc(var(--product-description-line-height) * 2);
    margin-block-start: 4px;
    margin-block-end: 12px;

    @include textEllipsis(2);

    @include upperDesktop {
      font-size: 16px;
      --product-description-line-height: 24px;
      margin-block-end: 16px;
    }
  }
  
  &__targetBtn {
    @include buttonPrimaryBySize('s');

    @include upperDesktop {
      @include buttonPrimaryBySize('m')
    }
  }
  
  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-block-start: 8px;
  }
  

  &__sponsorLink {
    margin-inline-start: auto;
  }
  
  &__spoiler {}
}