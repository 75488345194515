.spoiler {
  position: relative;
  z-index: 0;

  &__bubble {
    display: none;
    position: absolute;
    z-index: -1;
    overflow: hidden;
    box-sizing: border-box;
    padding-inline: 16px;
    padding-block: 12px calc(12px + 16px + 8px);
    border-radius: 24px;
    opacity: 0.7;
    color: var(--spoiler-bubble-text-color);
    font-size: 10px;
    line-height: 11px;

    &:before {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;
      inline-size: 100%;
      block-size: 100%;
      filter: blur(8px);
      background-color: var(--spoiler-bubble-background);
    }

    &_visible {
      display: block;
    }

    &_position-block {
      &_end {
        inset-block-end: -12px;
        padding-block: 12px calc(12px + 16px + 8px);
      }
      &_start {
        inset-block-start: -12px;
        padding-block: calc(12px + 16px + 8px) 12px;
      }
    }
    &_position-inline {
      &_start {
        inset-inline-start: -16px;
      }
      &_end {
        inset-inline-end: -16px;
      }
    }
  }

  &__anchor {}
}