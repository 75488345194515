@mixin sectionHeaderTextSize() {
  font-size: 28px;
  line-height: 36px;

  @include upperTablet {
    font-size: 32px;
    line-height: 40px;
  }

  @include onlyLargeDesktop {
    font-size: 40px;
    line-height: 48px;
  }
}

@mixin sectionHeaderMargin {
  margin-block-end: 28px;
  
  @include upperTablet {
    margin-block-end: 32px;
  }

  @include onlyLargeDesktop {
    margin-block-end: 40px;
  }
}