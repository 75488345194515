@import '~styles/common-mixins.scss';
@import '~atoms/Text/size-mixins.scss';
@import '~atoms/Button/size-mixins.scss';

$heightS: 384px;
$heightM: 416px;
$heightL: 424px;

.topOffersSection {
  &__header {
    color: var(--header-primary-section-text-color);

    @include sectionHeaderTextSize;
    @include sectionHeaderMargin;
  }

  &__grid {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(auto-fit, minmax(228px, 1fr));
    grid-gap: 8px;
    grid-auto-flow: dense;
    transition: max-height 1000ms ease-in-out;
    max-block-size: 10000px;

    @include upperWideMobile {
      grid-template-columns: repeat(auto-fit, minmax(312px, 1fr));
    }

    @include upperTablet {
      grid-gap: 16px;
    }

    @include onlyLargeDesktop {
      grid-gap: 24px;
    }

    &_collapsed {
      max-block-size: $heightS;

      @include upperWideMobile {
        max-block-size: $heightM;
      }

      @include onlyLargeDesktop {
        max-block-size: $heightL;
      }
    }
  }

  &__item {
    block-size: $heightS;

    @include upperWideMobile {
      block-size: $heightM;
    }

    @include onlyLargeDesktop {
      block-size: $heightL;
    }
  }

  &__showMoreBtn {
    margin-inline: auto;
    margin-block-start: 20px;
    @include buttonSecondaryBySize('s');

    @include upperWideMobile {
      @include buttonSecondaryBySize('m');
    }

    @include upperTablet {
      margin-block-start: 24px;
    }

    @include onlyLargeDesktop {
      margin-block-start: 32px;
    }
  }
}