@import "~styles/common-mixins.scss";

.pageContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .contentContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    padding: 0 16px;

    @include upperTablet {
      padding: 0 44px;
    }

    @include upperDesktop {
      padding: 0 28px;
    }

    @include onlyLargeDesktop {
      width: 1440px;
      padding: 0 108px;
    }
  }

  .contentContainer > * {
    margin-top: 64px;
  }

  .contentContainer > *:first-child {
    margin-top: 0;
  }
}
