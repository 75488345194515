@import '~styles/common-mixins.scss';

.frontContent {
  position: relative;
  background-color: var(--card-background-color);
  display: flex;
  flex-direction: column;
  justify-content: end;
  text-align: start;

  &__image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
    inline-size: 100%;
    block-size: 224px;
    max-inline-size: 224px;
    align-self: flex-end;

    @include upperWideMobile {
      block-size: 240px;
      max-inline-size: 240px;
    }
  }

  &__content {}

  &__title {
    margin-block-end: 4px;
    font-size: 32px;
    line-height: 40px;

    @include upperWideMobile {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &__description {
    margin-block-end: 20px;
    font-size: 14px;
    line-height: 20px;
    block-size: 40px;

    @include upperWideMobile {
      font-size: 16px;
      line-height: 24px;
      block-size: 48px;
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__detailsBtn {}

  &__sponsorLink {
    margin-inline-start: auto;
  }

  &__spoiler {
    white-space: nowrap;
  }
}