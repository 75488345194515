.actionTag {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: inherit;
  text-decoration: none;
  outline: none;

  &:not(:disabled) {
    cursor: pointer;
  }
}