@import '~styles/common-mixins.scss';
@import '~atoms/Button/size-mixins.scss';

.backContent {
  position: relative;
  background-color: var(--card-background-color);
  display: flex;
  flex-direction: column;
  justify-content: end;
  text-align: start;

  &__content {}

  &__title {
    margin-block-end: 2px;
    font-size: 32px;
    line-height: 40px;

    @include upperWideMobile {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &__description {
    margin-block-end: 24px;
    font-size: 14px;
    line-height: 20px;

    @include upperWideMobile {
      font-size: 16px;
      line-height: 24px;
      block-size: 48px;
    }
  }

  &__promocode {
    margin-block-end: 16px;

    @include buttonPromocodeBySize('m');

    @include upperWideMobile {
      @include buttonPromocodeBySize('l')
    }
  }

  &__deadline {
    margin-block: 16px 20px;
    align-self: center;
    font-size: 12px;
    line-height: 16px;
    color: var(--secondary-text-color);
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &__targetBtn {
    @include buttonPrimaryBySize('m');

    @include upperWideMobile {
      @include buttonPrimaryBySize('l')
    }
  }

  &__sponsorLink {
    margin-inline-start: auto;
  }

  &__spoiler {
    white-space: nowrap;
  }
}