.text {
  &__heading {
    font-family: SBSansDisplay;
    font-weight: 400;
  }

  &__headingBold {
    font-family: SBSansDisplay;
    font-weight: 600;
  }

  &__normal {
    font-family: SBSansText;
    font-weight: 400;
  }

  &__cond {
    font-family: SBSansTextCond;
    font-weight: 700;
  }
}