@import '~styles/common-mixins.scss';

$borderRadius: 360px;

@mixin buttonSizeSettingsPreset($size) {
  @if $size == 's' {
    block-size: 40px;
    font-size: 14px;
    line-height: 20px;
    padding-inline: 16px;
  } @else if $size == 'm' or not $size {
    block-size: 48px;
    font-size: 16px;
    line-height: 24px;
    padding-inline: 24px;
  } @else if $size == 'l' {
    block-size: 56px;
    font-size: 18px;
    line-height: 26px;
    padding-inline: 32px;
  }
}

@mixin buttonPrimarySizeSettings($size)  {
  @include buttonSizeSettingsPreset($size)
}

@mixin buttonSecondarySizeSettings($size)  {
  @include buttonSizeSettingsPreset($size)
}

@mixin buttonPromocodeSizeSettings($size)  {
  @include buttonSizeSettingsPreset($size)
}

@mixin buttonTextSizeSettings($size)  {
  font-size: 12px;
  line-height: 16px;

  & .button__icon {
    block-size: 16px;
    inline-size: auto;

    &:first-child {
      margin-inline-end: 2px;
    }
    
    &:last-child {
      margin-inline-start: 2px;
    }
  }
}

@mixin buttonStylesPreset {
  border-radius: $borderRadius;

  & .button__icon {
    block-size: 24px;
    inline-size: 24px;

    &:first-child {
      margin-inline-end: 8px;
    }
    
    &:last-child {
      margin-inline-start: 8px;
    }
  }
}

@mixin buttonPrimaryStyles {
  @include buttonStylesPreset;
  color: var(--button-primary-text-color);
  background: var(--button-primary-background);

  &:hover,
  &:active {
    background: var(--button-primary-background-hover);
  }
}

@mixin buttonSecondaryStyles {
  @include buttonStylesPreset;
  color: var(--button-secondary-text-color);
  background: none;

  @include useGradientBorder(var(--button-secondary-border), 2px);

  &:hover,
  &:active {
    @include useGradientBorder(var(--button-secondary-border-hover), 2px);
  }
}

@mixin buttonPromocodeStyles {
  @include buttonStylesPreset;
  color: var(--button-promocode-text-color);
  background: var(--button-promocode-background);

  &:hover,
  &:active{
    color: var(--button-promocode-text-color-hover);
  }

  &:hover{
    border: 1px solid var(--button-promocode-border-hover);
  }

  &:active{
    border: none;
  }
}

@mixin buttonTextStyles {
  font-size: inherit;
  color: var(--button-text-text-color);
  background: none;

  &:hover,
  &:active {
    color: var(--button-text-text-color-hover);
  }
}

@mixin useButtonTypeAndSize($type, $size) {
  @extend .button_#{$type}:global(.size_#{$size})
}

.button {
  $buttonSize: var(--button-size);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  white-space: nowrap;
  flex-shrink: 0;

  &__icon {
    color: inherit;
  }

  &_primary {
    @include buttonPrimaryStyles;

    @include setSizeS {
      @include buttonPrimarySizeSettings('s')
    }

    @include setSizeM(true) {
      @include buttonPrimarySizeSettings('m')
    }

    @include setSizeL {
      @include buttonPrimarySizeSettings('l')
    }
  }

  &_secondary {
    @include buttonSecondaryStyles;

    @include setSizeS {
      @include buttonSecondarySizeSettings('s')
    }

    @include setSizeM(true) {
      @include buttonSecondarySizeSettings('m')
    }

    @include setSizeL {
      @include buttonSecondarySizeSettings('l')
    }
  }

  &_promocode {
    @include buttonPromocodeStyles;

    @include setSizeS {
      @include buttonPromocodeSizeSettings('s')
    }

    @include setSizeM(true) {
      @include buttonPromocodeSizeSettings('m')
    }

    @include setSizeL {
      @include buttonPromocodeSizeSettings('l')
    }
  }
  &_text {
    @include buttonTextStyles;

    @include setSizeS(true) {
      @include buttonTextSizeSettings('s');
    }
  }

  &_custom {

  }  
}
