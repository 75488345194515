@import '~styles/common-mixins.scss';
@import '~atoms/Text/size-mixins.scss';
@import '~atoms/Button/size-mixins.scss';

.productsSection {
  &__header {
    color: var(--header-secondary-section-text-color);
    
    @include sectionHeaderTextSize;
    @include sectionHeaderMargin;
  }

  &__grid {
    display: grid;
    overflow: hidden;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    grid-gap: 8px;
    grid-auto-flow: dense;
    transition: max-height 1000ms ease-in-out;
    max-block-size: calc(var(--product-item-height) * 10);

    @include upperWideMobile {
      grid-template-columns: repeat(auto-fit, minmax(152px, 1fr));
    }

    @include upperTablet {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 16px;
    }

    @include upperDesktop {
      grid-template-columns: repeat(auto-fit, minmax(214px, 1fr));
    }

    @include onlyLargeDesktop {
      grid-template-columns: repeat(auto-fit, minmax(228px, 1fr));
      grid-gap: 24px;
    }

    &_collapsed {
      max-block-size: var(--product-item-height);
    }
  }

  &__showMoreBtn {
    margin-inline: auto;
    margin-block-start: 20px;
    @include buttonSecondaryBySize('s');

    @include upperWideMobile {
      @include buttonSecondaryBySize('m');
    }

    @include upperTablet {
      margin-block-start: 24px;
    }

    @include onlyLargeDesktop {
      margin-block-start: 32px;
    }
  }
}