@import '~styles/common-mixins.scss';

.headerContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;

  @include upperTablet {
    padding: 20px 44px;
  }

  @include upperDesktop {
    padding: 20px 28px;
  }

  @include onlyLargeDesktop {
    max-width: 1440px;
    padding: 24px 108px;
  }

  .sberLogo {
    display: block;
    box-sizing: border-box;
    cursor: pointer;
  }

  .enterButton {
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
  }
}
