@import '~shared/contexts/Theme/variables.scss';

@mixin initTheme($theme, $isDefault: false) {
  :global(.#{$themeClassNameBase}_#{$theme}) {
    @content;
    color: var(--text-color);
    background-color: var(--page-background);
  }

  @if $isDefault {
    @content;
    color: var(--text-color);
    background-color: var(--page-background);
  }
}

:root {
  --sber-yellow: #CDFFA6;
  --sber-blue: #39FFE7;
  --sber-green: #86FF99;
  --sber-deep-green: #00D56F;
  --sber-dark-blue: #1D404E;
  --sber-darkest-blue: #1D2D34;
  --almost-black: #001B1D;

  --white: #FFF;
  --ghostly-white: #F2F5F5;
  --grey: #7A9F9F;
  --grey-2: #AABBC1;
  --dark-grey: #688A97;


  --gradient-green-to-cian: linear-gradient(93deg, var(--sber-green) 0%, #0FF 100%);
  --gradient-yellow-green-blue: linear-gradient(102deg, #ECD613 0.61%, #0CC757 48.25%, #1AACFF 104.2%);
  --gradient-aquamarine-to-dark: linear-gradient(219deg, #00CDB4 0%, var(--sber-dark-blue) 65.6%);
  --gradient-blue-to-dark: linear-gradient(219deg, #0075FF 0%, var(--sber-dark-blue) 65.6%);
  --gradient-green-to-dark: linear-gradient(219deg, #00C543 0%, var(--sber-dark-blue) 65.6%);
  --gradient-violet-to-dark: linear-gradient(219deg, #4E46F5 0%, var(--sber-dark-blue) 65.6%);
  --gradient-green-to-blue: linear-gradient(92deg, #3EAB68 6.95%, #1D72AB 98.93%);

  --black: #000; // ?
  --white-unknown: #D8DFE2; // ?

  @include initTheme($themeKeyDefault, true) {
      --page-background: var(--almost-black);

      // text
      --text-color: var(--white);
      --secondary-text-color: var(--grey-2);
      --control-text-color: var(--ghostly-white);
      --canceled-text-color: var(--dark-grey);
      --background-text-color: var(--grey);
      --header-primary-section-text-color: var(--sber-blue);
      --header-secondary-section-text-color: var(--sber-deep-green);
      --title-text-color: var(--sber-green);

      // buttons
      --button-primary-text-color: var(--sber-dark-blue);
      --button-primary-background: var(--gradient-green-to-cian);
      --button-primary-background-hover: var(--sber-blue);
    
      --button-secondary-text-color: var(--white);
      --button-secondary-border: var(--gradient-green-to-cian);
      --button-secondary-border-hover: var(--sber-blue);
    
      --button-text-text-color: var(--control-text-color);
      --button-text-text-color-hover: var(--white);
    
      --button-promocode-text-color: var(--grey-2);
      --button-promocode-background: rgba(255, 255, 255, 0.16);
      --button-promocode-text-color-hover: var(--white);
      --button-promocode-border-hover: var(--sber-deep-green);

      --button-icon-color: var(--white);
      --button-icon-color-hover: var(--ghostly-white);
      --button-icon-color-active: var(--white);
      --button-icon-background: var(--sber-darkest-blue);
      --button-icon-background-hover: var(--sber-darkest-blue);

      // spoiler
      --spoiler-bubble-text-color: var(--white);
      --spoiler-bubble-background: var(--almost-black);

      // card
      --card-background-color: var(--sber-dark-blue);

      // badge
      --discount-text-color: var(--gradient-green-to-blue);
      --discount-background: var(--white);

      --prime-mark-text-gradient-color: var(--gradient-yellow-green-blue);
      --prime-mark-background-image: url( '../../source/flag-tag-background.svg');

      //carousel
      --carousel-progress-item-background-color: var(--sber-dark-blue);
      --carousel-progress-item-filler-color: var(--sber-deep-green);
  }
}