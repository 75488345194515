@import '~styles/common-mixins.scss';

.topOfferCard {
  position: relative;
  box-sizing: border-box;
  inline-size: 100%;
  block-size: 100%;
  padding: var(--top-offer-card-padding);

  border-radius: 20px;
  --top-offer-card-padding: 16px;

  @include upperWideMobile {
    border-radius: 24px;
    --top-offer-card-padding: 20px;
  }

  @include onlyLargeDesktop {
    border-radius: 32px;
    --top-offer-card-padding: 24px;
  }

  & > * {
    inline-size: 100%;
    block-size: 100%;
    position: absolute;
    inset: 0;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden; // For Safari
    transition: transform .5s ease;
    border-radius: inherit;
    padding: inherit;
    box-sizing: border-box;

    &:first-child {
      transform: perspective(600px) rotateY(0);
    }
    &:last-child {
      transform: perspective(600px) rotateY(180deg);
    }
  }

  &_turned > * {
    &:first-child {
      transform: perspective(600px) rotateY(-180deg);
    }
    &:last-child {
      transform: perspective(600px) rotateY(0);
    }
  }
}