@import '~styles/common-mixins.scss';

.footer {
  box-sizing: border-box;
  padding: 40px 16px;
  border-top: 1px solid var(--sber-dark-blue);
  width: 100%;

  @include upperTablet {
    padding: 40px 44px;
  }

  @include upperDesktop {
    padding: 40px 28px;
  }

  @include onlyLargeDesktop {
    padding: 48px 108px;
  }

  &__content {
    max-width: 1440px;
    margin: auto;
  }

  &__sberLogo {
    display: block;
    width: 84px;
    height: 24px;
  }

  &__contactSection {
    display: flex;
    flex-direction: column;

  }

  &__contactNumber {
    color: var(--white);
    font-size: 14px;
    line-height: 144%;
    margin: 24px 0 0 0;
  }

  &__contactDescription {
    color: var(--dark-grey);
    font-size: 14px;
    line-height: 144%;
    font-weight: 400;
    margin: 8px 0 0 0;
  }

  &__legalSection {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
  }

  &__legalText {
    margin: 12px 0 0 0;
    color: var(--dark-grey);
    font-size: 12px;
    font-weight: 400;
    line-height: 136%;
  }

  &__legalText:first-child {
    margin: 0;
  }
}