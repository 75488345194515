@mixin useGradientBorder($gradient, $borderSize) {
  position: relative;
  
  &:before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    padding: inherit;
    background: $gradient border-box;
    border: $borderSize solid transparent;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}
@mixin textEllipsis($numberOfLines) {
  display: -webkit-box;
  -webkit-line-clamp: $numberOfLines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin useGradientTextColor($gradient) {
  background: $gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin setSize($value, $isDefault: false) {
  @if $isDefault {
    @content;
  } @else {
    &:global(.size_#{$value}) {
      @content;
    }
  }
}

@mixin setSizeS($isDefault: false) {
  @include setSize('s', $isDefault) {
    @content;
  }
}

@mixin setSizeM($isDefault: false) {
  @include setSize('m', $isDefault) {
    @content;
  }
}

@mixin setSizeL($isDefault: false) {
  @include setSize('l', $isDefault) {
    @content;
  }
}

$breakPoints: ('mobile': 320px, 'wideMobile': 375px, 'tablet': 768px, 'desktop': 1024px, 'wideDesktop': 1440px);

@mixin belowMobile {
  @media (max-width:319px) {
    @content;
  }
}

@mixin upperMobile {
  @media (min-width:320px) {
    @content;
  }
}

@mixin belowWideMobile {
  @media (max-width:374px) {
    @content;
  }
}

@mixin upperWideMobile {
  @media (min-width:375px) {
    @content;
  }
}

@mixin belowTablet {
  @media (max-width:767px) {
    @content;
  }
}

@mixin upperTablet {
  @media (min-width:768px) {
    @content;
  }
}

@mixin belowDesktop {
  @media (max-width:1023px) {
    @content;
  }
}

@mixin upperDesktop {
  @media (min-width:1024px) {
    @content;
  }
}

@mixin belowLargeDesktop {
  @media (max-width:1439px) {
    @content;
  }
}

@mixin onlyLargeDesktop {
  @media (min-width:1440px) {
    @content;
  }
}
