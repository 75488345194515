@font-face {
  font-family: 'SBSansDisplay';
  font-style: normal;
  font-weight: 600;
  src: local('SBSansDisplay'), url('./SBSansDisplay/sbsans-display-semibold.otf') format('opentype');
}

@font-face {
  font-family: 'SBSansDisplay';
  font-style: normal;
  font-weight: 400;
  src: local('SBSansDisplay'), url('./SBSansDisplay/sbsans-display-regular.otf') format('opentype');
}

@font-face {
  font-family: 'SBSansTextCond';
  font-style: normal;
  font-weight: 400;
  src: local('SBSansTextCond'), url('./SBSansTextCond/sbsans-textcond-regular.otf') format('opentype');
}

@font-face {
  font-family: 'SBSansText';
  font-style: normal;
  font-weight: 400;
  src: local('SBSansText'), url('./SBSansText/sbsans-text-regular.ttf') format('truetype'), url('./SBSansText/sbsans-text-regular.otf') format('opentype');
}
