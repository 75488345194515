@import '~styles/theme/mixins.scss';
@import '~styles/common-mixins.scss';

.flagTag {
  background-image: var(--prime-mark-background-image);
  background-repeat: no-repeat;
  background-position: -12px -8px;
  font-size: 16px;
  line-height: 17px;
  padding-block: 10px 13px;
  padding-inline: 8px 19px;
  box-sizing: border-box;
  block-size: 44px;
  inline-size: 194px;

  & > span {
    @include useGradientTextColor(var(--prime-mark-text-gradient-color));
  }
}