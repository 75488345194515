@import '~styles/common-mixins.scss';

.header {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100%;

  &__primeMark {
    position: absolute;
    inset-block-start: var(--top-offer-card-padding, 0);
    inset-inline-start: -4px;
  }
  &__favoriteBtn {
    position: absolute;
    inset-inline-end: var(--top-offer-card-padding, 0);
    inset-block-start: var(--top-offer-card-padding, 0);
    border-radius: 360px;
    color: var(--button-icon-color);

    svg {
      display: block;
    }

    &:not(:disabled):hover,
    &:not(:disabled):active {
      svg {
        fill: currentColor;
      }
    }

    &:hover {
      color: var(--button-icon-color-hover);
    }

    &:active {
      color: var(--button-icon-color-active);
    }
  }
}